// import "core-js/stable";
// import "regenerator-runtime/runtime";

import process from "process";
import postcss from "postcss";
import autoprefixer from "autoprefixer";
import csso from "postcss-csso";

window.process = process;

class ConvertCss {
  static stringToDom(cssString, domElement, callback = null) {
    postcss([autoprefixer, csso])
      .process(cssString, { map: false, from: undefined })
      .then((value) => {
        if (value && value.css) {
          console.log("Updating DOM element with CSS", value.css);
          domElement.innerHTML = value.css;

          if (callback) {
            callback();
          }
        } else {
          // debugger;
          console.error("could not convert css");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static debug(cssString) {
    postcss([autoprefixer, csso])
      .process(cssString, { map: false, from: undefined })
      .then((value) => {
        console.log(value.css);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export default ConvertCss;
